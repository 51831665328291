* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Noto Sans', sans-serif;
  scrollbar-color: #b8b8b8 #f5f8fd;
}

*::-webkit-scrollbar {
  width: 0.875rem;
}

*::-webkit-scrollbar-track {
  background: #f5f8fd;
}

*::-webkit-scrollbar-thumb {
  background: #b8b8b8;
  border-radius: 2rem;
  border: 0.25rem #f5f8fd solid;
  background-clip: content-box;
}

*::-webkit-scrollbar-thumb:hover {
  background: #858585;
}

html {
  font-size: 16px;
}

body {
  background: #f5f8fd;
}
